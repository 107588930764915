.multi-details-container .input-label
{
    color: #363636; 
    margin: 0.5em 0em 0.2em 0em;
    font-size: 14px;
    font-weight: 500;
}

.multi-details-container .input-field
{
    border-radius: 5px;
    margin: 0px 0px 20px 0px;
    height: 40px;
    width: 100%;
}

.multi-details-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}

.multi-details-container .input-textarea
{
    border-radius: 5px;
}

.multi-details-container .full-width
{
    width: 100%;
}

.multi-details-container .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.multi-details-container .category, .multi-details-container .weeks-period, .multi-details-container .design, .multi-details-container .distance-type
{
    padding:0px; 
    margin: 0em; 
    width: 92%;
    border-radius: 5px;
}

.multi-details-container .time-cycle-select
{
    padding:0px; 
    margin: 0em 0em 1em 0em; 
    width: 100%;
    border-radius: 5px;
}

.multi-details-container .finish-header
{
    width: 92%;
}

.multi-details-container .buttons-container
{
    margin: 2em 0em 0em 0em;
}

.multi-details-container .stages-btn
{
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-top: 0em;
    align-self: center;
}

.multi-details-container .mt
{
    margin-top: 0.7em;
}

.multi-details-container .buttons-container .next-btn, 
.multi-details-container .buttons-container .save-btn
{
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-top: 0em;
    padding: 0em 2em 0em 2em;
}

.multi-details-container .error-label
{
    margin-top: 0px !important;
    padding: 0px;
}

.multi-details-container .upload-card-container
{
    display: flex;
    justify-content: center;
}

.multi-details-container .time-cycles-container
{
    background-color: #f6f6f6; 
    padding: 1em;
    border-radius: 5px
}

.multi-details-container .stage-card {
    margin: 0.7rem 0rem; 
    background-color: #f6f6f6; 
    padding: 1rem;
    border-radius: 5px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.multi-details-container .image-title
{
    display: flex;
    align-items: center;
    column-gap: 1em;
}

.multi-details-container .image-title .image
{
    max-height: 4rem; 
    max-width: 4rem;
    border-radius: 5px;
}

.multi-details-container .image-title .image-empty
{
    max-height: 3.8rem; 
    max-width: 3.8rem;
    border-radius: 5px;
    border: 2px solid grey;
    padding: 8px;
}

.multi-details-container .stage-card .edit-icon {
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.stages-upload-card
{
    width: 23em;
}

.stages-upload-card .upload-icon
{
    font-size: 35px;
    padding: 15px;
    border-radius: 10em;
    background-color: white;
    color: #18a6c4;
}

.stages-upload-card .file-icon
{
    height: 90px;
    width: 90px;
}

.stages-upload-card .selected-file-preview
{
    height: 100%;
    width: 100%;
}

.stages-upload-card .remove-file-icon
{
    right: -2px;
    top: -2px;
    position: absolute;
    cursor: pointer;
}

.stages-upload-card .select-file-label
{
    color: #6f9fbb;
    margin: 15px 0px 0px 0px;
    font-size: 16px;
}

.stages-upload-card .size-limit-label
{
    margin: 5px 0px 0px 0px !important; 
    color: #4a4a49; 
    font-size: 18px;
}

.stages-upload-card .upload-pictures-label
{
    margin: 10px 0px 0px 0px;
    color: #4a4a49; 
    font-size: 18px;
}

.stages-upload-card .upload-card-empty
{
    margin: 0em 0em 0em 0em;
    text-align: center;
    border: 1px solid #dce3ea !important;
    border-radius: 10px;
    height: 15em;
    background-color: #f6f6f6;
    align-items: center;
    display: flex;
    justify-content: center;
}

.stages-upload-card .upload-card-filled
{
    margin: 0em 0em 0em 0em;
    text-align: center;
    border: 1px solid #dce3ea;
    background-color: #f6f6f6;
    border-radius: 10px;
    height: 15em;
    align-items: center;
    display: flex;
    justify-content: center;
}

.stages-upload-card .upload-card-filled .selected-file-container
{
    height: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.stages-upload-card .upload-card-filled .selected-file-container .seleted-file-details
{
    display: block;
    position: relative;
    max-width: 60%;
    padding: 1em;
    border-radius: 10px;
}

.stages-upload-card .upload-card-container
{
    border: none !important;
    border-radius: 10px !important;
}