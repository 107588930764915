.notification-dictionary-container {
  margin: 3.5em 3.5em 0em 3.5em;
}

.notification-dictionary-header {
  display: flex;
  justify-content: space-between;
  margin: 0em 0em 0.5em 0em;
}

.dictionary-table {
  border: 1px solid #707070;
}
