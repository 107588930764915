.challenges-container
{
    margin: 3.5em 3.5em 0em 3.5em;
}

.challenges-container .challenges-header
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
}

.challenges-container .title
{
    font-weight: bold;
    color: #363636;
}

.challenges-container .input-label
{
    color: #363636; 
    margin: 0em 0em 0.2em 0em;
    font-size: 14px;
    font-weight: 500;
}

.challenges-container .finish-line-input
{
    margin-top: 1em;
}

.challenges-container .distance-input
{
    margin-bottom: 1em;
}

.challenges-container .input-info
{
    color: #afafaf;
    margin: 0.3em 0em 0em 0em;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
}

.challenges-container .dropdown
{
    padding:0px; 
    margin-bottom: 1em; 
    width: 92%;
    border-radius: 5px;
}

/* .buttons-container
{
    margin: 0em 3.5em;
}

.buttons-container .next-btn
{
    margin-top: 2em;
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 0em 2em 0em 2em;
}

.buttons-container .save-btn
{
    margin-top: 2em;
    margin-left: 1em;
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 0em 2em 0em 2em;
} */

.challenges-container .message-input
{
    border-radius: 5px;
    height: 40px;
}

.challenges-container .edit-icon
{
    cursor: pointer;
}

.challenges-container .create-btn
{
    color:#ffffff; 
    border-radius: 5px;
    background-color: #717171;
    height: 40px;
}

.challenges-container .archived-challenge-btn
{
    color:#ffffff; 
    border-radius: 5px;
    background-color: #717171;
    height: 40px;
}

.challenges-container .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none;
}

.challenges-container .challenges-table .ant-table-thead>tr>th {
    border-bottom: 1px solid #afafaf;
}

.challenges-container .challenges-table .ant-table-tbody>tr>td {
    border-bottom: 1px solid #afafaf;
}

.challenges-container .ant-table-thead>tr>th {
    background-color: white;
}

.challenges-container .challenges-header .search-container
{
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 0.5em;
}

.challenges-container .challenges-header .search-box .ant-input-group-addon:first-child, 
.challenges-container .challenges-header .search-box .ant-input-group-addon:first-child .ant-select .ant-select-selector, 
.challenges-container .challenges-header .search-box .ant-input-group>.ant-input:first-child
{
    height: 40px;
}

.challenges-container .challenges-header .search-box .ant-input-group .ant-input-group-addon > button
{
    height: 40px;
}

.challenges-table 
{
    border: 1px solid #707070;
}

.challenges-container .ant-table-pagination.ant-pagination {
    margin: 16px 16px 16px 0px;
}

.challenges-container .ant-pagination-item-active {
    border-color: #707070;
}

.challenges-container .ant-pagination-item-active a {
    color: #363636;
}

.series-details-container .ant-modal-title {
    text-align: center;
}

.series-details-container .input-label
{
    margin: 0px 0px 3px 0px;
    font-size: 16px;
    color: #363636;
}

.series-details-container .input-subtitle
{
    color: #afafaf;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
}

.series-details-container .input-field
{
    margin: 0px 0px 20px 0px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #afafaf;
    width: 100%;
}

.series-details-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}

.series-details-container .ant-modal-header {
    border-bottom: none;
    padding-top: 2.5em;
}

.series-details-container .ant-modal-title {
    font-size: 30px;
    color: #363636;
}

.series-details-container .ant-modal-footer {
    text-align: left;
    border: none;
    padding-top: 0px;
    padding-bottom: 1em;
    margin-left: 0.4em;
}

.series-details-container .ant-modal-footer .ant-btn-primary {
    height: 40px;
    width: 18em;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background-color: #717171;
}

.series-details-container .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 15px;
    width: 155px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #444;
    color: #363636;
    background-color: white;
}

.series-details-container .option-label
{
    text-align: center;
    margin: 2em 0em 1.5em 0em;
    font-style: italic;
}

.series-details-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
}

.series-details-container .error-label
{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}

.series-details-container .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    border: 1px solid #afafaf;
}

.series-details-container .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    border: #717171;
}

.series-details-container .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #717171;
}

.series-details-container .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #d7d7d7;
    border: none;
}

.series-details-container .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    color: #3b3b3b;
}

.series-details-container .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #717171;
    border: none;
}

.series-details-container .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #fff;
}

.series-details-container .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
    background-color: #717171;
    height: 0.1em;
}

.series-details-container .input-number-field
{
    margin: 0px 0px 20px 0px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #afafaf;
    width: 100%;
}

.series-details-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
}

.series-details-container .input-label
{
    margin: 0px 0px 3px 0px;
    font-size: 16px;
    color: #363636;
}

.series-details-container .series-challenge-input
{
    border-radius: 5px;
    height: 40px;
    padding:0px; 
    margin: 0em; 
    border-radius: 5px;
}

.series-details-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}