.release-details-container .input-select
{
    border-radius: 5px;
    height: 40px;
    width: 10em;
}

.release-details-container .input-label
{
    color: #363636; 
    margin: 0.5em 0em 0.2em 0em;
    font-size: 14px;
    font-weight: 500;
}

.release-details-container .release-date-picker
{
    border-radius: 5px;
    height: 40px;
    width: 15em;
    margin-bottom: 10px;
}

.release-details-container .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.release-details-container .release-challenge-dropdown
{
    padding: 0px; 
    margin: 0px 0px 10px 0px; 
    width: 13em;
    border-radius: 5px;
}

.release-details-container .release-date-container
{
    display: flex;
    flex-wrap: wrap;
    column-gap: 1em;
    margin-bottom: 10px;
}

.release-details-container .nospace
{
    margin: 0px !important;
}

.release-details-container .release-options-container
{
    display: flex;
    flex-wrap: wrap;
    column-gap: 1em;
}

.release-details-container .buttons-container
{
    margin: 0em 0em 0em 0em;
    display: flex;
    column-gap: 1em;
}

.release-details-container .buttons-container .next-btn,
.release-details-container .buttons-container .save-btn
{
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-top: 1em;
    padding: 0em 2em 0em 2em;
}