.error
{
    background-color: #ff5858 !important;
    color: white !important;
}

.success
{
    background-color: #50c563 !important;
    color: white !important;
}

.ant-notification-notice-close {
    color: white !important;
}

.ant-notification-notice-closable .ant-notification-notice-message {
    color: white;
}