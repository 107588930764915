.report-card
{
    height: 100%;
}

.card-container
{
    display: flex; 
    justify-content: center;
    margin-top: 2em;
    height: 100%;
}

.card-container .card-column
{
    border-radius: 10px;
    background-color: #f6f6f6;
    height: 100% !important;
}

.card-container .card-row
{
    padding: 1.4em 0.7em 0em 0.7em;
}

.card-container .card-column .card-row .avatar-col
{
    display: flex;
    justify-content: center;
}

.card-container .card-column .card-row .card-avatar
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebebeb;
    border: 1px solid #d9d9d9;
}

.card-container .card-column .card-row .premium-count
{
    display: flex; 
    align-items: center; 
    column-gap: 0.3em;
}

.card-container .card-column .card-avatar > img
{
    max-width:50%;
    max-height:50%;
    object-fit: contain;
}

.card-container .card-column .no-spacing
{
    margin: 0px; 
    padding: 0px 0px 0.2em 0px;
}

.card-container .card-column .card-title
{
    margin-bottom: 0px;
    color: #444444;
    font-weight: 600;
}

.card-container .card-column .card-subtitle
{
    font-size: 18px !important;
    color: #363636;
    font-weight: 400;
}

.card-container .card-column .card-count
{
    color: #363636;
}

.card-container .card-column .card-date-range
{
    margin-left: 5px;
    color: #363636;
    font-size: 16px;
}

.card-container .card-column .card-icon
{
    color: #717171;
    margin-right: 1em;
    height: 22px;
    width: 22px;
    image-rendering: -webkit-optimize-contrast;
}

.filter-buttons
{
    margin: 1em 0em 0em 0em;
}

.card-apply-filter
{
    margin: 0em 0.7em 0em 0em;
    background-color: #717171 !important;
    color: white !important;
    border-radius: 5px !important;
}

.card-reset-filter
{
    background-color: white !important;
    border: 1px solid #717171 !important;
    border-radius: 5px !important;
}

.start-date-label
{
    margin: 0px 0px 3px 0px !important;
}

.end-date-label
{
    margin: 0px 0px 3px 0px !important;
}

.start-date-picker
{
    width: 9em;
    border-radius: 5px !important;
    border: 1px solid #afafaf !important;
}

.end-date-picker
{
    width: 9em;
    border-radius: 5px !important;
    border: 1px solid #afafaf !important;
}

.ant-popover-placement-bottomRight .ant-popover-arrow
{
    display: none;
}

.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight {
    padding: 3px 0px 0px 0px !important;
}