.footer-container
{
    margin-top: 5em;
    background-color: #ebebeb;
    display: flex;
    width: 100%;
    padding-top: 3em;
    padding-bottom: 5em;
    padding-left: 1.5em;
}

.footer-container .footer-col-1
{
    padding-left: 2em;
}

.footer-container .footer-col-1 .footer-title
{
    font-weight: bold;
}

.footer-container .footer-col-1 .footer-subtitle
{
    font-weight: 600;
}

.footer-container .footer-col-1 .footer-address-1
{
    margin: 0;
    font-weight: 400;
}

.footer-container .footer-col-1 .footer-address-2
{
    margin: 0;
    font-weight: 400;
}

.footer-container .footer-col-1 .footer-address-3
{
    font-weight: 400;
}

.footer-container .footer-col-2 .footer-text
{
    margin: 0;
    font-weight: 400;
}

.footer-container .footer-col-3 .footer-text
{
    margin: 0;
    font-weight: 400;
}

.footer-container .footer-col-3
{
    padding-top: 6em; 
    text-align: center;
}

.footer-container .footer-col-4
{
    text-align: center;
}

.footer-container .footer-col-4 .title
{
    margin-top: 2.5em;
}

.social-icons
{
    margin-left: 8px !important;
    height: 40px;
    width: 40px;
}

@media screen and (max-width: 768px) {
    .footer-container .footer-col-1,
    .footer-container .footer-col-2,
    .footer-container .footer-col-3
    {
        text-align: center;
    }

    .footer-container .footer-col-1
    {
        padding-left: unset;
    }
}