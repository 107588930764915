.create-challenge-container
{
    margin: 3.5em 3.5em 0em 3.5em;
}

.create-challenge-container .title
{
    font-weight: bold;
    color: #363636;
}

.create-challenge-container .challenge-header .next-btn
{
    color: #ffffff;
    background-color: #717171;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-left: 0.5em;
    padding: 0em 0.5em;
}


@media screen and (max-width: 768px) {
    .create-challenge-container .challenge-header
    {
        font-size: 20px;
    }

    .create-challenge-container .challenge-count
    {
        font-size: 18px;
    }
}

