.company-details-container
{
    margin: 3.5em 3.5em 0em 3.5em;
}

.company-details-container .header
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
}

.company-details-container .header .buttons-container
{
    display: flex;
    align-items: center;
    column-gap: 1em;
    margin: 0px !important;
}

.company-details-container .header .buttons-container .extract-icon
{
    height: 30px;
    width: 30px;
}

.company-details-container .header .create-user-btn 
{
    color:#ffffff; 
    border-radius: 5px;
    background-color: #717171;
    height: 40px;
}

.company-details-container .icons-container
{
    display: flex; 
    column-gap: 0.7em; 
    align-items: center;
}

.company-details-container .title
{
    font-weight: bold;
    color: #363636;
}

.ant-select-selection-placeholder
{
    align-self: center;
}

.company-details-container .users-table 
{
    border: 1px solid #707070;
}

.company-details-container .edit-icon
{
    cursor: pointer;
}

.company-details-container .delete-icon
{
    cursor: pointer;
    font-size: 24px;
    color: rgb(255, 68, 68);
}

.company-details-container .users-table .ant-table-thead>tr>th {
    border-bottom: 1px solid #afafaf;
}

.company-details-container .users-table .ant-table-tbody>tr>td {
    border-bottom: 1px solid #afafaf;
}

.company-details-container .ant-table-thead>tr>th {
    background-color: white;
}

.company-details-container .ant-table-pagination.ant-pagination {
    margin: 16px 16px 16px 0px;
}

.company-details-container .ant-pagination-item-active {
    border-color: #707070;
}

.company-details-container .ant-pagination-item-active a {
    color: #363636;
}