.department-list-container {
  margin: 3.5em 3.5em 0em 3.5em;
}

.department-list-container .delete-button {
  font-size: 24px;
  color: rgb(255, 68, 68);
  cursor: pointer;
}

.department-table {
  border: 1px solid #cecece;
}
