.qr-container
{
    margin: 3.5em 3.5em 0em 3.5em;
}

.qr-container .qr-header
{
    display: flex;
    justify-content: space-between;
    margin: 0em 0em 0.5em 0em;
}

.qr-container .qr-header .search-container
{
    display: flex;
    column-gap: 1em;
    flex-wrap: wrap;
    row-gap: 0.5em;
}

.qr-container .qr-header .search-box .ant-input-group-addon:first-child, 
.qr-container .qr-header .search-box .ant-input-group-addon:first-child .ant-select .ant-select-selector, 
.qr-container .qr-header .search-box .ant-input-group>.ant-input:first-child
{
    height: 40px;
}

.qr-container .qr-header .search-box .ant-input-group .ant-input-group-addon > button
{
    height: 40px;
}

.qr-container .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none;
}

.qr-container .ant-table-pagination.ant-pagination {
    margin: 16px 16px 16px 0px;
}

.qr-container .add-qr-button
{
    background-color: #717171;
    border-radius: 5px;
    border: 1px solid#d9d9d9;
    color: white;
    height: 40px;
}

.qr-container .qr-table .company-name-col:hover
{
    color: #648bd9;
    text-decoration: underline;
    cursor: pointer;
}

.qr-container .qr-table
{
    border: 1px solid #707070;
}

.qr-container .qr-table .ant-table-tbody>tr>td {
    border-bottom: 1px solid #afafaf;
}

.qr-container .qr-table .ant-table-thead>tr>th {
    border-bottom: 1px solid #afafaf;
}

.qr-container .view-qr-code
{
    cursor: pointer;
    color: #648bd9;
    text-decoration: underline;
}

.qr-container .edit-icon 
{
    width: 24px;
    height: 24px; 
    cursor: pointer;
}

.qr-disabled
{
    margin: 2em 0em 0em 0em;
    opacity: 0.3;
}

.qr-enabled
{
    margin: 2em 0em 2em 0em;
}

.modal-container .input-label
{
    margin: 0px 0px 3px 0px;
    font-size: 16px;
    color: #363636;
}

.modal-container .input-subtitle
{
    color: #afafaf;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
}

.modal-container .company-name
{
    margin: 0px 0px 20px 0px;
    height: 40px;
    border-radius: 5px;
    width: 100%;
}

.modal-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    height: 35px;
}

.modal-container .input-field
{
    margin: 0px 0px 20px 0px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #afafaf;
    width: 100%;
}

.modal-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}

.modal-container .user-limit-input
{
    margin: 0px 0px 2px 0px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #afafaf;
    width: 100%;
}

.modal-container .ant-modal-header {
    border-bottom: none;
    padding-top: 2.5em;
}

.modal-container .ant-modal-title {
    font-size: 30px;
    color: #363636;
}

.modal-container .ant-modal-footer {
    text-align: left;
    border: none;
    padding-top: 0px;
    padding-bottom: 1em;
    margin-left: 0.4em;
}

.modal-container .ant-modal-footer .ant-btn-primary {
    height: 40px;
    width: 155px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background-color: #717171;
}

.modal-container .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 15px;
    width: 155px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #444;
    color: #363636;
    background-color: white;
}

.modal-container .member-type
{
    margin: 0px;
}

.modal-container .generate-qr-container
{
    text-align: center;
}

.modal-container .option-label
{
    text-align: center;
    margin: 2em 0em 1.5em 0em;
    font-style: italic;
}

.modal-container .generate-qr-btn
{
    width: 210px;
    height: 40px;
    color: white;
    border-radius: 5px;
    border: solid 1px #d9d9d9;
    background-color: #717171;
    margin: 2em 0em 0em 0em;
}

.modal-container .select-sa
{
    width: 100%;
    margin: 0px 0px 20px 0px;
}

.modal-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
}

.modal-container .error-label
{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}

.modal-container .notification-template-label
{
    margin-top: 2em;
}

.modal-container .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    border: 1px solid #afafaf;
}

.modal-container .notification-template-list
{ 
    width: 100%;
    border-radius: 5px;
}

.view-qr-container .ant-modal-footer .ant-btn-primary {
    height: 40px;
    width: 100px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background-color: #717171;
}

.view-qr-container .ant-modal-header {
    border-bottom: none;
}

.view-qr-container .ant-modal-title {
    font-size: 20px;
    padding-top: 0.3em;
    font-weight: 600;
    color: #363636;
}

.view-qr-container .ant-modal-footer {
    border-top: none;
}

.view-qr-container .qr-image-container
{
    display: flex;
    justify-content:center;
}

.modal-upload-card .upload-icon
{
    font-size: 35px;
    padding: 15px;
    border-radius: 10em;
    background-color: white;
    color: #18a6c4;
}

.modal-upload-card .file-icon
{
    height: 90px;
    width: 90px;
}

.modal-upload-card .selected-file-preview
{
    height: 100%;
    width: 100%;
}

.modal-upload-card .remove-file-icon
{
    right: -2px;
    top: -2px;
    position: absolute;
    cursor: pointer;
}

.modal-upload-card .select-file-label
{
    color: #6f9fbb;
    margin: 15px 0px 0px 0px;
    font-size: 16px;
}

.modal-upload-card .size-limit-label
{
    margin: 5px 0px 0px 0px !important; 
    color: #4a4a49; 
    font-size: 18px;
}

.modal-upload-card .upload-pictures-label
{
    margin: 10px 0px 0px 0px;
    color: #4a4a49; 
    font-size: 18px;
}

.modal-upload-card .upload-card-empty
{
    margin: 1.8em 0em 0em 0em;
    text-align: center;
    border: 1px solid #dce3ea !important;
    border-radius: 10px;
    height: 15em;
}

.modal-upload-card .upload-card-filled
{
    margin: 1.8em 0em 0em 0em;
    text-align: center;
    border: 1px solid #dce3ea;
    background-color: #f6f6f6;
    border-radius: 10px;
    height: 15em;
}

.modal-upload-card .upload-card-filled .selected-file-container
{
    height: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.modal-upload-card .upload-card-filled .selected-file-container .seleted-file-details
{
    display: block;
    position: relative;
    max-width: 60%;
    padding: 1em;
    border-radius: 10px;
}

.modal-upload-card .upload-card-container
{
    border: none !important;
    border-radius: 10px !important;
}

.qr-container .ant-table-thead>tr>th {
    background-color: white;
}

.qr-container .ant-pagination-item-active {
    border-color: #707070;
}

.qr-container .ant-pagination-item-active a {
    color: #363636;
}


.qr-container .delete-button .anticon {
    font-size: 24px;
    color: rgb(255, 68, 68);
    cursor: pointer;
  }