.spinner-container
{
    position: fixed;
    top:0;
    display:flex; 
    z-index: 100000;
    background:rgba(40, 40, 40, .8);
    align-items:center; 
    justify-content:center; 
    height:100%;
    width:100%;
}

.spinner-container .loading-icon
{
    font-size: 50px;
    font-weight: bolder;
    color: white;
    stroke: white;
}