.reports-container
{
    margin: 0em 2.2em 3.5em 2.2em !important;
    height: fit-content;
}

.videos-container
{
    margin: 0em 3em 0em 3em !important;
}

.reports-container .col-1
{
    min-height: 100%;
}

.reports-container .col-2
{
    min-height: 100%;
}

.reports-container .col-3
{
    min-height: 100%;
}

.videos-header
{
    display: flex; 
    justify-content: space-between; 
    align-items: baseline; 
    margin: 0em 3.5em 0em 3.5em;
}

.videos-header .view-all
{
    color: #363636;
}

@media (min-width: 1200px)
{
    .videos-container .ant-col-xl-5 {
        display: block;
        flex: 0 0 20% !important;
        max-width: 20.83333333%;
    }
}

