.archived-challenges-container
{
    margin: 3.5em 3.5em 0em 3.5em;
}

.archived-challenges-container .header
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
}

.archived-challenges-container .title
{
    font-weight: bold;
    color: #363636;
}

.archived-challenges-container .input-label
{
    color: #363636; 
    margin: 0em 0em 0.2em 0em;
    font-size: 14px;
    font-weight: 500;
}

.archived-challenges-container .finish-line-input
{
    margin-top: 1em;
}

.archived-challenges-container .distance-input
{
    margin-bottom: 1em;
}

.archived-challenges-container .input-info
{
    color: #afafaf;
    margin: 0.3em 0em 0em 0em;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
}

.archived-challenges-container .dropdown
{
    padding:0px; 
    margin-bottom: 1em; 
    width: 92%;
    border-radius: 5px;
}

/* .buttons-container
{
    margin: 0em 3.5em;
}

.buttons-container .next-btn
{
    margin-top: 2em;
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 0em 2em 0em 2em;
}

.buttons-container .save-btn
{
    margin-top: 2em;
    margin-left: 1em;
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 0em 2em 0em 2em;
} */

.archived-challenges-container .message-input
{
    border-radius: 5px;
    height: 40px;
}

.archived-challenges-container .create-btn
{
    color:#ffffff; 
    border-radius: 5px;
    background-color: #717171;
    height: 40px;
}

.archived-challenges-container .edit-icon
{
    cursor: pointer;
}

.archived-challenges-container .challenge-btn 
{
    color:#ffffff; 
    border-radius: 5px;
    background-color: #717171;
    height: 40px;
}

.archived-challenges-container .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none;
}

.archived-challenges-container .challenges-table .ant-table-thead>tr>th {
    border-bottom: 1px solid #afafaf;
}

.archived-challenges-container .challenges-table .ant-table-tbody>tr>td {
    border-bottom: 1px solid #afafaf;
}

.archived-challenges-container .ant-table-thead>tr>th {
    background-color: white;
}

.challenges-table 
{
    border: 1px solid #707070;
}

.archived-challenges-container .ant-table-pagination.ant-pagination {
    margin: 16px 16px 16px 0px;
}

.archived-challenges-container .ant-pagination-item-active {
    border-color: #707070;
}

.archived-challenges-container .ant-pagination-item-active a {
    color: #363636;
}