.company-list-container {
  margin: 3.5em 3.5em 0em 3.5em;
}

.company-list-container .delete-button {
  font-size: 24px;
  color: rgb(255, 68, 68);
  cursor: pointer;
}

.company-table {
  border: 1px solid #cecece;
}

.info-button,
.lock-button {
  font-size: 24px;
  cursor: pointer;
}
