.milestone-modal-container .steps-container {
    display: flex;
    justify-content: center;
}

.milestone-modal-container .ant-modal-title {
    text-align: center;
}

.milestone-modal-container .input-label
{
    margin: 0px 0px 3px 0px;
    font-size: 16px;
    color: #363636;
}

.milestone-modal-container .step
{
    max-width: 100px;
    padding: 0px;
}

.milestone-modal-container .row-container
{
    width:100%;
    margin: 0em 0.5em !important;
}

.milestone-modal-container .input-subtitle
{
    color: #afafaf;
    font-weight: 400;
    font-style: italic;
    font-size: 13px;
}

.milestone-modal-container .input-field
{
    margin: 0px 0px 20px 0px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #afafaf;
    width: 100%;
}

.milestone-modal-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}

.milestone-modal-container .ant-modal-header {
    border-bottom: none;
    padding-top: 2.5em;
}

.milestone-modal-container .ant-modal-title {
    font-size: 30px;
    color: #363636;
}

.milestone-modal-container .ant-modal-footer {
    text-align: left;
    border: none;
    padding-top: 0px;
    padding-bottom: 1em;
    margin-left: 0.4em;
}

.milestone-modal-container .ant-modal-footer .ant-btn-primary {
    height: 40px;
    width: 155px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background-color: #717171;
}

.milestone-modal-container .ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 15px;
    width: 155px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #444;
    color: #363636;
    background-color: white;
}

.milestone-modal-container .option-label
{
    text-align: center;
    margin: 2em 0em 1.5em 0em;
    font-style: italic;
}

.milestone-modal-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
}

.milestone-modal-container .error-label
{
    margin: 0px 0px 20px 0px;
    padding: 0px;
    font-size: 14px;
}

.milestone-modal-container .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    border: 1px solid #afafaf;
}

.milestone-modal-container .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    border: #717171;
}

.milestone-modal-container .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #717171;
}

.milestone-modal-container .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #d7d7d7;
    border: none;
}

.milestone-modal-container .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    color: #3b3b3b;
}

.milestone-modal-container .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #717171;
    border: none;
}

.milestone-modal-container .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #fff;
}

.milestone-modal-container .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
    background-color: #717171;
    height: 0.1em;
}

.milestone-modal-container .input-field
{
    margin: 0px 0px 20px 0px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #afafaf;
    width: 100%;
}

.milestone-modal-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
}

.milestone-modal-container .input-label
{
    margin: 0px 0px 3px 0px !important;
    font-size: 16px;
    color: #363636;
}

.milestone-modal-container .input-select {
    margin-bottom: 20px;
    width: 100%;
}

.milestone-modal-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}

.milestone-modal-container input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 3px;
    margin-top: 10px;
    background-clip: content-box;
    border: 1px solid #bbbbbb;
    background-color: #e7e6e7;
    border-radius: 50%;
}
  
.milestone-modal-container input[type="radio"]:checked {
    background-color: #636363;
}

.milestone-modal-container .answers-container
{
    border-radius: 5px;
    margin: 0em !important;
    padding: 1em 0em;
    background-color: #f6f6f6;
}