.form-container
{
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-container .login-form
{
    width: 350px;
    background-color: #ebebeb;
}

.form-container .login-form-title
{
    text-align: center;
    font-weight: bold;
}

.form-container .login-form .ant-form-item
{
    margin-bottom: 1em;
}

.form-container .login-form label 
{
    font-size: 15px;
    color: #717171;
    font-weight: 600;
}

.form-container .login-form .submit-btn
{
    background-color: #717171;
    color: white;
    width: 150px;
    border-radius: 5px;
    align-self: center;
}
.form-container .login-form .submit-btn:hover
{
    background-color: #717171;
    color: white;
    border: none;
}

.form-container .login-form .error-label
{
    margin-top: 5px;
    font-size: 14px;
    float: left
}

@media screen and (max-width: 500px) {
    .form-container .login-form
    {
        width: 250px;
        background-color: #ebebeb;
    }
}