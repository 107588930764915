.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    display: none;
}

.ant-select-dropdown {
    background-color: #f6f6f6 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #717171 !important;
    border: 1px solid #717171 !important;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #717171 !important;
    border: 1px solid #717171 !important;
}

.ant-btn-primary {
    border-color: #717171 !important;
    background: #717171 !important;
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
    border-color: #717171 !important;
    background: #717171 !important;
}

.ant-table-filter-trigger.active {
    color: #212121 !important;
}

.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper, .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
    background-color: #ebebeb !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #717171 !important;
}

.ant-btn-link {
    color: #717171 !important;
}

.select-search-input
{
    width: 90% !important;
    margin: 0.5em 0em 0.5em 0em !important;
}