.notification-container
{
    margin: 3.5em 3.5em 0em 3.5em;
}

.notification-container .form
{
    width: 100% !important;
}

.notification-container .form .is-app-screen
{
    margin-top: 0.5em;
    width: 100%;
}

.notification-container .form .challenge 
{
    padding:0px; 
    margin: 0em; 
    width: 100%;
    border-radius: 5px;
}

.notification-container .form .overview-container
{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0em 1em 0rem; 
    background-color: #f6f6f6; 
    padding: 0.5rem 1rem;
    border-radius: 5px; 
    width: 100%;
}

.notification-container .form .overview-header
{
    color: #363636;
    margin: 0em 0em 0.3em 0em;
    font-size: 25px;
    font-weight: 650;
    padding: 0rem 0.2rem;
}

.notification-container .form .overview-text
{
    color: #363636; 
    margin: 0.5em 0em;
    font-size: 16px;
    font-weight: 500;
}

.notification-container .notification-header
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.notification-container .notification-header .title
{
    font-weight: bold;
    color: #363636;
}

.notification-container .input-label
{
    color: #363636; 
    margin: 0em 0em 0.2em 0em;
    font-size: 14px;
    font-weight: 500;
}

.notification-container .delivery-date-label
{
    color: #363636; 
    margin: 0em 0em 0.2em 0em;
    font-size: 14px;
    font-weight: 500;
}

.notification-container .template-name-label
{
    color: #363636;
    font-size: 14px; 
    font-weight: 400; 
    margin: 0px 0px 3px 0px;
}

.notification-container .input-info
{
    color: #afafaf;
    margin: 0.3em 0em 0em 0em;
    font-size: 13px;
    font-weight: 400;
    font-style: italic;
}

.notification-container .dropdown-container
{
    padding: 12em 0em 0em 0em;
}

.notification-container .dropdown-container .ant-select-item-option-grouped 
{
    padding-left: 0.8em;
}

.notification-container .dropdown-container .ant-select-item-group {
    font-size: 14px;
    color: black;
    font-weight: 600;
}

.notification-container .specific-users
{
    padding: 0px;
    position: absolute; 
    top: 4em; 
    width: 100%;
    border-radius: 5px;
    border: 1px solid #afafaf;
}

.notification-container .specific-companies
{
    padding: 0px;
    position: absolute; 
    top: 7em; 
    margin: 0.5em 0em 0em 0em; 
    width: 100%;
    border-radius: 5px;
    border: 1px solid #afafaf;
}

.notification-container .specific-departments
{
    padding: 0px;
    position: absolute; 
    top: 10.5em; 
    margin: 0.5em 0em 0em 0em; 
    width: 100%;
    border-radius: 5px;
    border: 1px solid #afafaf;
}

.notification-container .specific-sas
{
    padding:0px; 
    position: absolute; 
    top: 10.7em; 
    margin: 0.5em 0em 0em 0em; 
    width: 100%;
    border-radius: 5px;
    border: 1px solid #afafaf;
}

.notification-container .delivery-date
{
    padding:0px; 
    margin: 0em; 
    width: 92%;
    border-radius: 5px;
}

.notification-container .template-name
{
    padding:0px; 
    margin: 0em; 
    width: 92%;
    border-radius: 5px;
    border: 1px solid #afafaf;
}

.notification-container .create-btn
{
    color:#ffffff; 
    border-radius: 5px;
    background-color: #717171;
    height: 40px;
}

.notification-container .send-message
{
    margin-top: 2em;
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    padding: 0em 2em 0em 2em;
}

.notification-container .message-input
{
    border-radius: 5px;
    height: 40px;
}

.notification-container .date-picker
{
    width: 75%;
    border-radius: 5px;
    height: 40px;
    border: 1px solid #afafaf;
}

.notification-container .time-picker
{
    width: 75%;
    border-radius: 5px;
    height: 40px;
    border: 1px solid #afafaf;
}

.notification-container .upload-card-container
{
    display: flex;
    justify-content: center;
}

.notification-container .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.notification-container .radio-group-container .radio-option
{
  margin: 1em 0em 1em 0em;
}

.notification-container .error-label
{
    margin-top: 0px !important;
    padding: 0px;
}

.notification-upload-card
{
    width: 23em;
}

.notification-upload-card .upload-icon
{
    font-size: 35px;
    padding: 15px;
    border-radius: 10em;
    background-color: white;
    color: #18a6c4;
}

.notification-upload-card .file-icon
{
    height: 90px;
    width: 90px;
}

.notification-upload-card .selected-file-preview
{
    height: 100%;
    width: 100%;
}

.notification-upload-card .remove-file-icon
{
    right: -2px;
    top: -2px;
    position: absolute;
    cursor: pointer;
}

.notification-upload-card .select-file-label
{
    color: #6f9fbb;
    margin: 15px 0px 0px 0px;
    font-size: 16px;
}

.notification-upload-card .size-limit-label
{
    margin: 5px 0px 0px 0px !important; 
    color: #4a4a49; 
    font-size: 18px;
}

.notification-upload-card .upload-pictures-label
{
    margin: 10px 0px 0px 0px;
    color: #4a4a49; 
    font-size: 18px;
}

.notification-upload-card .upload-card-empty
{
    margin: 2.5em 0em 0em 0em;
    text-align: center;
    border: 1px solid #dce3ea !important;
    border-radius: 10px;
    height: 15em;
}

.notification-upload-card .upload-card-filled
{
    margin: 1.8em 0em 0em 0em;
    text-align: center;
    border: 1px solid #dce3ea;
    background-color: #f6f6f6;
    border-radius: 10px;
    height: 15em;
}

.notification-upload-card .upload-card-filled .selected-file-container
{
    height: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.notification-upload-card .upload-card-filled .selected-file-container .seleted-file-details
{
    display: block;
    position: relative;
    max-width: 60%;
    padding: 1em;
    border-radius: 10px;
}

.notification-upload-card .upload-card-container
{
    border: none !important;
    border-radius: 10px !important;
}

@media screen and (max-width: 768px) {
    .notification-container .notification-header .title
    {
        font-size: 24px;
    }
    
    .notification-container .form
    {
        width: 100%;
    }

    .notification-container .dropdown-container
    {
        padding: 0em;
    }

    .notification-container .specific-users
    {
        position: relative;
        top: unset;
    }

    .notification-container .specific-companies
    {
        position: relative;
        top: unset;
    }

    .notification-container .specific-departments
    {
        position: relative;
        top: unset;
    }

    .notification-container .specific-sas
    {
        position: relative;
        top: unset;
    }

    .notification-container .delivery-date
    { 
        width: 100%;
    }

    .notification-container .date-picker
    {
        width: 100%;
    }

    .notification-container .time-picker
    {
        width: 100%;
    }
}

.screen-type, .challenge, .auction {
    width: 100%;
}