.notification-table {
  border: 1px solid #707070;
}

.notification-container .edit-button {
  margin-right: 1rem;
  cursor: pointer;
}

.notification-container .delete-button .anticon {
  font-size: 24px;
  color: rgb(255, 68, 68);
  cursor: pointer;
}
