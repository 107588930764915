.ant-radio-checked .ant-radio-inner{
    border-color: #afafaf !important ;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: #717171;
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: #717171;
  }

  .radio-group-container .radio-option
  {
    margin: 1em 0em 1em 0em;
  }