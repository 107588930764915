.video-card-container :hover .video-card-play-icon
{
    display: flex !important;
    cursor: pointer;
}

.video-card-container .video-card-col
{
    height: 8em;
}

.video-card-container .video-card-col .video-card-image
{
    width: 100%; 
    height:100%; 
    background-size: cover !important; 
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9;
    border-radius: 5px;
}

.video-card-container .video-card-col .video-card-image .video-card-play-icon
{
    font-size: 40px;
    color: #717171;
    background: #d9d9d9;
    border-radius: 1em;
}

.video-card-container .video-card-details .video-card-title
{
    margin: 0px; 
    padding: 8px 0px 0em 0px;
}

.video-card-container .video-card-details .video-card-subtitle
{
    margin: 0px; 
    padding: 2px 0px 0em 0px;
    font-weight: 500;
}

.video-card-container .video-card-details .video-card-time
{
    margin: 0px; 
    padding: 0px;
    font-weight: 500;
}

.video-player 
{
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    top: 0px !important;
}

.video-player .ant-modal-content {
    background-color: transparent;
}

.video-player .ant-modal-body 
{
    padding: 0px !important;
    width: 98.7vw;
    margin: 0px !important;
}

.video-player .ant-modal-root .ant-modal-mask {
    border: 5px solid green !important;
}

.video-player .anticon {
    color: white;
    font-size: 30px;
    font-weight: bolder;
    padding-top: 1em;
    padding-right: 1em;
}

.video-player .video-frame
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-player .frame-container
{
    padding: 56.5% 0 0 0;
    position: relative;
}