.header-container
{
    position: sticky;
    top: 0px; 
    z-index: 1000;
}

.mobile-drawer .menu-options
{
    list-style-type: none; 
    padding: 0px;
    margin: 0px;
}

.mobile-drawer > .menu-option
{
    border: 5px solid red;
}

.mobile-drawer .ant-drawer-body 
{
    padding: 10px 24px;
}

.mobile-drawer .menu-options .menu-option
{
    color: #212121 !important;
}

.mobile-drawer .menu-options .ant-typography
{
    padding: 10px 0px;
}

.header-row
{
    background-color: #ebebeb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 3.5em;
}

.header-row .header-title
{
    font-weight: 650;
}

.header-row .menu-container
{
    display: flex;
    flex-wrap: wrap;
    column-gap: 2em;
    align-items: center;
}

.header-row .menu-container .user-logout
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 5px;
}

.header-row .menu-option
{
    color: #212121;
}

.header-row h5.ant-typography 
{
    margin: 0px !important;
}

.header-row h3.ant-typography 
{
    margin: 0px !important;
}

.header-row .menu-btn-container
{
    display: none;
}

@media screen and (max-width: 768px) {
    .header-row .menu-container
    {
        display: none;
    }
    
    .header-row .menu-btn-container
    {
        display: flex;
    }
}