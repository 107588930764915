.challenge-details-container .input-label
{
    color: #363636; 
    margin: 0.5em 0em 0.2em 0em;
    font-size: 14px;
    font-weight: 500;
}

.challenge-details-container .input-field
{
    border-radius: 5px;
    height: 40px;
    margin: 0px 0px 20px 0px;
}

.challenge-details-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}

.challenge-details-container .input-textarea
{
    border-radius: 5px;
}

.challenge-details-container .challenge-name
{
    width: 100%;
}

.challenge-details-container .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.challenge-details-container .category, .challenge-details-container .difficulty, .challenge-details-container .design, .challenge-details-container .distance-type
{
    padding:0px; 
    margin: 0em; 
    width: 92%;
    border-radius: 5px;
}

.challenge-details-container .buttons-container
{
    margin: 2em 0em 0em 0em;
    display: flex;
    column-gap: 1em;
}

.challenge-details-container .buttons-container .next-btn, 
.challenge-details-container .buttons-container .save-btn
{
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-top: 0em;
    padding: 0em 2em 0em 2em;
}

.challenge-details-container .error-label
{
    margin-top: 0px !important;
    padding: 0px;
}

.challenge-details-container .upload-card-container
{
    display: flex;
    justify-content: center;
}

.challenge-upload-card
{
    width: 23em;
}

.challenge-upload-card .upload-icon
{
    font-size: 35px;
    padding: 15px;
    border-radius: 10em;
    background-color: white;
    color: #18a6c4;
}

.challenge-upload-card .file-icon
{
    height: 90px;
    width: 90px;
}

.challenge-upload-card .selected-file-preview
{
    height: 100%;
    width: 100%;
}

.challenge-upload-card .remove-file-icon
{
    right: -2px;
    top: -2px;
    position: absolute;
    cursor: pointer;
}

.challenge-upload-card .select-file-label
{
    color: #6f9fbb;
    margin: 15px 0px 0px 0px;
    font-size: 16px;
}

.challenge-upload-card .size-limit-label
{
    margin: 5px 0px 0px 0px !important; 
    color: #4a4a49; 
    font-size: 18px;
}

.challenge-upload-card .upload-pictures-label
{
    margin: 10px 0px 0px 0px;
    color: #4a4a49; 
    font-size: 18px;
}

.challenge-upload-card .upload-card-empty
{
    margin: 2.5em 0em 0em 0em;
    text-align: center;
    border: 1px solid #dce3ea !important;
    border-radius: 10px;
    height: 15em;
    background-color: #f6f6f6;
    align-items: center;
    display: flex;
    justify-content: center;
}

.challenge-upload-card .upload-card-filled
{
    margin: 1.8em 0em 0em 0em;
    text-align: center;
    border: 1px solid #dce3ea;
    background-color: #f6f6f6;
    border-radius: 10px;
    height: 15em;
    align-items: center;
    display: flex;
    justify-content: center;
}

.challenge-upload-card .upload-card-filled .selected-file-container
{
    height: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.challenge-upload-card .upload-card-filled .selected-file-container .seleted-file-details
{
    display: block;
    position: relative;
    max-width: 60%;
    padding: 1em;
    border-radius: 10px;
}

.challenge-upload-card .upload-card-container
{
    border: none !important;
    border-radius: 10px !important;
}


@media screen and (max-width: 550px) {
    .challenge-details-container .category, .challenge-details-container .difficulty, .challenge-details-container .design, .challenge-details-container .distance-type
    { 
        width: 100%;
    }
}




