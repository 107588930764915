.notification-container .header
{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.notification-container .header .title
{
    font-weight: bold;
    color: #363636;
}

.notification-container .form
{
    width: 40%;
}

@media screen and (max-width: 768px) {
    .notification-container .form
    {
        width: 100%;
    }

    .notification-container .header .title
    {
        font-size: 24px;
    }
}