.company-details-container .input-label
{
    color: #363636; 
    margin: 0.5em 0em 0.2em 0em;
    font-size: 14px;
    font-weight: 500;
}

.company-details-container .input-field
{
    border-radius: 5px;
    height: 40px;
    margin: 0px 0px 20px 0px;
}

.company-details-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}

.company-details-container .input-textarea
{
    border-radius: 5px;
}

.company-details-container .full-width
{
    width: 100%;
}

.company-details-container .user-type,
.company-details-container .membership
{
    padding:0px; 
    margin: 0em; 
    width: 100%;
    border-radius: 5px;
}

.company-details-container .department-name
{
    margin: 0px 0px 20px 0px;
    height: 40px;
    border-radius: 5px;
    width: 100%;
}

.company-details-container .nospace
{
    margin: 0px 0px 0px 0px !important;
}

.company-details-container .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.company-details-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    height: 35px;
}

.company-details-container .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border-radius: 5px;
    width: 100%;
}

.company-details-container .buttons-container .save-btn
{
    color: #ffffff;
    background-color: #717171;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-top: 0em;
    padding: 0em 2em 0em 2em;
}

.company-details-container .buttons-container
{
    margin: 2.5em 0em 0em 0em;
    display: flex;
    column-gap: 1em;
}